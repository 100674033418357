.banner-01 {
  .card-img {
    height: 420px;
  }

}

.banner-02 {
  .card-img {
    height: 400px;
  }

  @include media-breakpoint-up(sm) {
    .card-img {
      height: 450px;
    }

  }
}

.banner-03 {
  .card-img {
    height: 350px;
  }

  @include media-breakpoint-up(sm) {
    .card-img {
      height: 400px;
    }

  }
}

.banner-04 {
  .card-img {
    padding-bottom: 120%;
  }

  @include media-breakpoint-up(lg) {
    .card-img {
      padding-bottom: 160.10144927536%;
    }
  }
}

.banner-05 {
  .card-img {
    height: 344px;
  }
}

.banner-06 {
  .card-img {
    height: 450px;
  }

  @include media-breakpoint-up(lg) {
    .card-img {
      height: 750px;
    }
  }
}

.banner-07 {
  .card-img {
    height: 400px;
  }

  @include media-breakpoint-up(md) {
    .card-img {
      height: 650px;
    }

  }
}

.banner-08 {
  .card-img {
    height: 400px;
  }

  @include media-breakpoint-up(md) {
    .card-img {
      height: 690px;
    }

  }
}

.banner-09 {
  .card-img {
    height: 330px;
  }
}

.banner-10 {
  .card-img {
    height: 400px;
  }

  @include media-breakpoint-up(sm) {
    .card-img {
      height: 600px;
    }

  }

  @include media-breakpoint-up(md) {
    .card-img {
      height: 900px;
    }
  }
}