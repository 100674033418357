.slick-slider {
  margin-left: -15px;
  margin-right: -15px;

  .box {
    background-color: transparent !important;
    font-size: $font-size-base;

    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
  }

  :focus {
    outline: none;
  }

}

.custom-arrows {
  .slick-arrow {
    display: none !important;
    border: 0;

    i {
      font-size: 40px;
    }

    &:hover {
      box-shadow: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .slick-arrow {
      display: block !important;
    }
  }
}

.dot-lg-0 {
  @include media-breakpoint-up(lg) {
    .slick-dots {
      display: none !important;
    }
  }
}

.arrow-0 {
  .slick-arrow {
    display: none !important;
  }
}

.arrow-lg {
  @include media-breakpoint-up(lg) {
    .slick-arrow {
      display: flex !important;
    }
  }
}

.slick-slide {
  height: inherit;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.slick-track {
  display: flex;
}

.slick-vertical {
  height: auto;

  .slick-track {
    display: block;
  }

  .slick-dots {
    right: 50px;
    left: auto;
    top: 50%;
    flex-flow: column;
    align-items: flex-end;
    transform: translateY(-50%);
  }
}

.slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: $white;
  width: 48px;
  height: 48px;
  font-size: 14px;
  border-radius: 50%;
  color: $body-color;
  border: 2px solid $border-color;
  z-index: 100;
  transition: all 0.3s;

  &:hover {
    color: $secondary;
    border-color: transparent;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.slick-disabled {
    cursor: no-drop;
  }

  &.slick-prev {
    left: -60px;
    right: auto;
  }

  &.slick-next {
    left: auto;
    right: -60px;
  }

  i {
    font-size: 18px;
    line-height: inherit;
  }

  cursor: pointer;
}

.custom-arrows-02-prev,
.custom-arrows-02-next {
  transform: none;
}

.custom-arrows-03 {
  .slick-prev {
    left: 35px;
  }

  .slick-next {
    right: 35px;
  }
}

.custom-arrows-04 {
  @include media-breakpoint-up(xxl) {
    .slick-prev {
      left: -148px;
    }

    .slick-next {
      right: -148px;
    }
  }
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 30px;

  li {
    cursor: pointer;
    text-align: center;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    display: inline-block;
    line-height: 8px;
    width: 8px;
    height: 8px;
    background: $secondary;
    border-radius: 50%;
  }
}

.slider .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.slick-dots li.slick-active,
.slick-dots li:hover,
.slick-arrow:active,
.slick-dots li:active,
.slick-arrow:focus,
.slick-dots li:focus {
  opacity: 1;
}

.slick-dots li.slick-active span {
  width: 14px;
  height: 14px;
  line-height: 14px;
  display: inline-block;
  border: 1px solid $secondary;
  background: transparent;
}

.slick-dots-light {
  .slick-dots li span {
    background: $white;
  }

  .slick-dots li.slick-active span {
    border-color: $white;
    background: transparent;
  }
}

.custom-dots-01 {
  .slick-dots {
    span {
      line-height: 10px;
      width: 10px;
      height: 10px;
      background: #ccc;
    }
  }

  li {
    padding: 0 7.5px;

    &.slick-active span {
      width: 10px;
      height: 10px;
      background: $secondary;
    }
  }
}

.custom-slider-02 {
  .slick-dots {
    position: absolute;
    bottom: 30px;
    @include media-breakpoint-up(lg) {
      right: calc(100vw / 2 - 992px / 2 + 30px);
    }
    @media (min-width: 1200px) {
      right: calc(100vw / 2 - 1170px / 2 + 30px);
    }
    @media screen and (min-width: 1750px) {
      right: calc(100vw / 2 - 1750px / 2 + 30px);
    }

    @include media-breakpoint-down(md) {
      left: calc(100vw / 2 - 768px / 2 + 45px);
      right: auto;
      bottom: 20px;
    }
    @include media-breakpoint-down(sm) {
      left: calc(100vw / 2 - 576px / 2 + 45px);
    }
    @include media-breakpoint-down(xs) {
      left: 30px;
    }

    li:not(.slick-active) {
      display: none;
    }

    .slick-active {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .dot, .dot-divider, .divider-value {
      height: auto;
      width: auto;
      background-color: transparent;
      border: none;
    }

    .dot {
      font-size: 20px;
      font-weight: 600;
      color: $white;
    }

    .dot-divider {
      display: inline-block;
      content: '';
      width: 100px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      margin: 0 15px;
      position: relative;
      z-index: 1;

    }

    .divider-value {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      background-color: $white;
      z-index: 2;
      width: 100%;
    }

  }

  &.dots-white {
    .slick-dots {
      .dot {
        color: $white;

      }

      .dot-divider {
        background-color: rgba(255, 255, 255, 0.17);
      }

      .divider-value {
        background-color: $white;
      }
    }
  }

  &.slider-01 {

    @include media-breakpoint-down(md) {
      .slick-dots {
        left: calc(100vw / 2 - 768px / 2 + 30px);
      }

    }
    @include media-breakpoint-down(sm) {
      .slick-dots {
        left: calc(100vw / 2 - 576px / 2 + 30px);
      }
    }
    @include media-breakpoint-down(xs) {
      .slick-dots {
        left: 15px;
      }
    }
  }
}

.custom-slider-03 {
  .slick-arrow {
    top: -125px;
    transform: translateY(0);

    &.slick-next {
      right: 14%;
    }

    &.slick-prev {
      left: auto;
      right: 22%;
    }
  }

  .slick-dots {
    font-size: 18px;
    color: $secondary;
    position: absolute;
    top: -137px;
    right: 17%;

    .dot-divider,
    .dot-text,
    .dot {
      height: auto;
      width: auto;
      background-color: transparent;
      border: none;
    }

    li:not(.slick-active) {
      display: none;
    }

    .dot-text {
      padding-right: 5px;
    }
  }
}

.dot-md-0 {
  @include media-breakpoint-up(md) {
    .slick-dots {
      display: none !important;
    }
  }
}

.client-logo .slick-current {
  .opacity-hover-10 {
    opacity: 1 !important;
  }
}

@media (max-width: 1440px) {
  .custom-slider-03 {
    .slick-arrow {
      &.slick-prev {
        right: 25%;
      }
    }

    .slick-dots {
      right: 18%;
    }
  }
}


@include media-breakpoint-up(xl) {
  .custom-slider-04 {
    width: 65vw;

    .slick-list {
      padding: 0 24.3% 0 0 !important;
    }
  }
}

@media (min-width: 1440px) {
  .custom-slider-04 {
    width: 62vw;
  }
}


