.canvas-sidebar {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	transition: 0.5s;

	.canvas-overlay {
		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);

	}
	.card-body{
		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 7px #cccccc;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $secondary;
			outline: 1px solid $secondary;
		}
	}

	.canvas-close {
		cursor: pointer;
	}

	&.show {
		transform: translateX(0);

		.canvas-overlay {
			left: 100%;
			width: 3000px;
		}
	}
}

.cart-canvas {
	right: 0;
	left: auto;
	width: 450px;
	transform: translateX(100%);

	.progress {
		height: 4px;
		border-radius: 0;
		z-index: 1;
		overflow: visible;

		.indicator {
			width: 30px;
			height: 30px;
			border: 1px solid $primary;
			background-color: #fff;
			z-index: 2;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&.show {
		transform: translateX(0);

		.canvas-overlay {
			right: 100%;
			left: auto;
		}
	}

}

.sidenav {
	display: block;
	height: 100%;
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	transition: 0.5s;
	width: 350px;
	transform: translateX(-100%);


	.nav-link {
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}

	.nav-link.dropdown-toggle {
		display: flex;

		.caret {
			margin-left: auto;
		}

		.caret::after {
			content: '\f105';
			font-family: "Font Awesome 5 Pro";
			font-weight: 400;
			transition: all 0.2s;
		}

	}

	.nav-link.dropdown-toggle[aria-expanded="true"] {
		.caret::after {
			content: '\f107';
		}

	}

	.dropright .dropdown-toggle::after, .dropdown-toggle::after {
		display: none;
	}

	.dropdown-menu {
		position: static !important;
		box-shadow: none;
		padding-top: 0;
		background-color: transparent;
		transform: translate3d(0, 0, 0) !important;
		border: none;

		.dropdown-header {
			padding-left: 0;
			margin-bottom: 0 !important;
		}

		.dropdown-item {
			padding-left: 0;
			color: $secondary;

			.dropdown-link {
				display: inline-block;
				border-bottom: 1px solid transparent;

			}

			&:hover, &:focus, &.active, &:active {
				background: transparent;
				color: $primary;

				.dropdown-link {
					border-bottom-color: $primary;

				}
			}

		}
	}
}

.filter-canvas {
	width: 350px;
	transform: translateX(-100%);
	@include media-breakpoint-down(xs) {
		width: 320px;
	}
}
